
.collection-visual {
    &__title {
        margin: 0 auto;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding: 32px 40px;
        text-align: center;
        transition: transform 0.3s ease;
        background-color: #fff;
        text-transform: uppercase;
        &.scrolled-top {
            transform: translateY(-120%);
        }
    }
}

@media (min-width: 768px) {
}
