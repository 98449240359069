
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}

@media (min-width: 768px) {
    .v-tab {
        padding: 0 12px;
    }
}
