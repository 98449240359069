
::v-deep {
    #contents {
        padding-top: 0;

        // 상품목록
        .css-lvn3eu,

        // 페이지네이션
        .css-1my61mc,

        // 스와이퍼 버튼
        .css-1nz7joe,
        .css-1npt2ci {
            display: none;
        }
    }
}
