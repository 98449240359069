
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        .container {
            position: relative;
        }
        &__sheet {
            max-width: 90%;
            padding: 30px;
            margin: -220px auto 0;
            position: relative;
            z-index: 1;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
}
.figures-card {
    text-align: center;
    position: relative;
    > .v-responsive {
        display: flex;
        justify-content: center;
    }
    &__name {
        padding: 40px 60px 0;
        font-size: 12px;
        color: #ddb57e;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.1;
    }
    &__line {
        width: 20px;
        height: 2px;
        background-color: var(--v-primary-base);
        margin: 12px auto 0;
        transition: all ease-out 0.15s;
    }
    &__number {
        font-size: var(--tit-font-size-xxl);
        font-family: var(--font-tertiary);
        letter-spacing: -0.1em;
        line-height: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__button {
        border-width: 2px;
        width: 140px;
        color: var(--v-primary-base);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
    }
    &:hover {
        .figures-card {
            &__line {
                width: 42px;
            }
            &__button {
                color: #fff;
                background-color: var(--v-primary-base);
                border-color: var(--v-primary-base);
            }
        }
    }
}
@media (min-width: 768px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin: 0;
                position: absolute;
                top: 50%;
                left: var(--container-gutter);
                transform: translateY(-50%);
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--1 {
            padding-top: 80px;
        }
    }
}
