
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
    }
}
.youtube {
    width: 100%;
    aspect-ratio: 16/9;
}
.sns-card {
    overflow: hidden;
    &__inner {
        height: 100%;
        position: relative;
        z-index: 1;
        color: #fff;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            z-index: -1;
        }
    }
    &__sub-title {
        color: #ddb57e;
        font-size: 12px;
        text-transform: uppercase;
    }
    &__line {
        margin: 14px auto 28px;
        width: 8px;
        height: 2px;
        background-color: #ddb57e;
        transition: all ease-out 0.15s;;
    }
    &__title {
        font-family: var(--font-tertiary);
        font-weight: 700;
        font-size: var(--tit-font-size-sm);
        line-height: 1.1;
    }
    &__button {
        border-width: 2px;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: inherit;
        width: 100%;
        height: 100%;
        transition: all 0.2s;
    }
    &:hover {
        .sns-card {
            &__line {
                width: 20px;
            }
            &__button {
                background-color: #ddb57e !important;
                border-color: #ddb57e;
            }
        }
        &::after {
            transform: scale(1.1);
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -90px;
                    bottom: -250px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 80px;
        }
        .sub-sheet {
            max-width: initial;
        }
    }
}
