
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        .container {
            position: relative;
        }
        &__sheet {
            max-width: 90%;
            padding: 30px;
            margin: -220px auto 0;
            position: relative;
            z-index: 1;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
    }
    &--1 {
        padding-top: 80px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
            > .v-image {
                margin-top: 40px;
            }
        }
        &--right {
            > .v-image {
                margin-top: 40px;
                margin-bottom: 40px;
            }
        }
    }
}
.brand-card {
    position: relative;
    margin-left: 104px;
    padding: 30px;
    padding-left: 134px;
    display: flex;
    align-items: center;
    border-bottom: 6px solid var(--v-primary-base);
    &__image {
        position: absolute;
        left: -104px;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
        .v-image {
            transition: all 0.5s;
        }
    }
    &__find {
        margin-top: 14px;
        font-size: var(--txt-font-size-sm);
        color: var(--v-primary-base);
        position: relative;
        padding-left: 30px;
        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 1px;
            background: currentColor;
            left: 0;
            top: 54%;
        }
    }
    &:hover {
        .brand-card {
            &__image {
                .v-image {
                    transform: scale(1.1);
                }
            }
            &__find {
                animation: findOutMore 0.5s forwards ease-in-out;
                &::before {
                    animation: findOutMoreBefore 0.5s forwards ease-in-out;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin: 0;
                position: absolute;
                top: 50%;
                right: var(--container-gutter);
                transform: translateY(-50%);
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                }
            }
        }
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--1 {
            padding-top: 120px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
                > .v-image {
                    margin-top: 80px;
                }
            }
            &--right {
                > .v-image {
                    margin-bottom: 80px;
                }
            }
        }
    }
}

// Animations
@keyframes findOutMore {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes findOutMoreBefore {
    from {
        width: 0;
    }
    to {
        width: 20px;
    }
}
