
::v-deep {
    #contents {
        padding-top: 0;
    }
    .v-main {
        background-color: var(--v-grey-lighten5);
    }
}
.sub-section {
    &--visual {
        &__sheet {
            max-width: 90%;
            padding: 30px;
            position: relative;
            z-index: 1;
            margin: -60px auto 0;
            .title-line {
                width: 30px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin: 20px 0;
            }
            .v-breadcrumbs {
                color: #ddb57e;
            }
        }
        .visual-image {
            position: relative;
            overflow: visible;
            .vertical-title {
                display: none;
            }
        }
    }
    &--1 {
        padding-top: 40px;
    }
    .sub-sheet {
        max-width: 90%;
        margin: 0 auto;
        &--left {
            padding-top: 40px;
            // > .v-image {
            //     margin-top: 40px;
            // }
        }
        &--right {
            // > .v-image {
            //     margin-top: 40px;
            //     margin-bottom: 40px;
            // }
        }
    }
}
.quote {
    text-align: center;
    &__text {
        font-family: var(--font-tertiary);
        font-style: italic;
        font-size: var(--tit-font-size-sm);
        line-height: 1.2;
        padding: 14px 0;
    }
    &__mark {
        display: inline-flex;
        transform: translateY(-4%);
        width: 34px;
    }
    &__from {
        margin-top: 6px;
        font-size: 12px;
        font-weight: 700;
        color: #ddb57e;
    }
}
@media (min-width: 1232px) {
    .sub-section {
        &--visual {
            &__sheet {
                max-width: 600px;
                padding: 50px;
                margin-top: -180px;
                margin-left: 50px;
                .title-line {
                    width: 30px;
                    margin: 20px 0;
                }
            }
            .visual-image {
                &::after {
                    content: "";
                    position: absolute;
                    left: 14px;
                    bottom: 0;
                    width: 2px;
                    background-color: var(--v-primary-base);
                    transform: translateY(100%);
                    height: 0;
                    animation: verticalLine 0.5s forwards ease-in-out;
                }
                .vertical-title {
                    display: block;
                    position: absolute;
                    left: -90px;
                    bottom: -250px;
                    font-size: var(--tit-font-size-sm);
                    font-family: var(--font-tertiary);
                    font-style: italic;
                    color: var(--v-primary-base);
                    line-height: 1;
                    transform: translateY(-40px) rotate(90deg);
                    opacity: 0;
                    animation: verticalTitle 0.5s forwards ease-in-out 0.5s;
                }
            }
        }
        &--1 {
            padding-top: 40px;
        }
        .sub-sheet {
            max-width: 500px;
            &--left {
                margin-left: 100px;
                padding-top: 80px;
                // > .v-image {
                //     margin-top: 80px;
                // }
            }
            &--right {
                transform: translateY(-160px);
                // > .v-image {
                //     margin-bottom: 80px;
                // }
            }
        }
    }
}

// Animations
@keyframes verticalLine {
    from {
        height: 0;
    }
    to {
        height: 120px;
    }
}
@keyframes verticalTitle {
    from {
        transform: translateY(-40px) rotate(90deg);
        opacity: 0;
    }
    to {
        transform: translateY(0) rotate(90deg);
        opacity: 1;
    }
}
